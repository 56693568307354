<template>
  <div class="password-page">
    <div class="options">
      <menu-option v-for="(preference, i) in preferenceOptions" v-bind="preference" :key="i" :type="type" />
    </div>
  </div>
</template>

<script>
import MenuOption from './components/Option.vue';

export default {
  components: {
    MenuOption,
  },
  data() {
    return {
      type: this.$route.query.type,
      preferenceOptions: [
        {
          icon: ['fad', 'user'],
          isPreferential: false,
          title: 'Normal',
        },
        {
          icon: ['fad', 'user-headset'],
          isPreferential: true,
          title: 'Preferencial',
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    handleSelectPreference(preferentialOption) {
      this.handleSelectPreference(preferentialOption.value);
    },
    load() {
      if (!this.type) this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.password-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  .options {
    .tile {
      background-color: $gray-color-ultralight;
      border: $border-width solid $gray-color-light;
      border-radius: $border-radius;
      color: $primary-color;
      cursor: pointer;
      margin: $layout-spacing-lg;
      width: 28rem;

      .tile-title {
        font-weight: 600;
      }

      .tile-subtitle {
        color: $gray-color-dark;
        margin-top: $border-width-lg;
      }
    }
  }
}
</style>
