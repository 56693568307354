<template>
  <st-tile :icon="icon" :centered="true" size="xl" @click="generate">
    <div class="tile-title">{{ title }}</div>
    <div class="tile-subtitle">Gerar senha de atendimento</div>
  </st-tile>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
      default: () => ['fad', 'print'],
    },
    type: {
      type: String,
    },
    isPreferential: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    generate() {
      if (this.processing) return;

      this.processing = true;

      const postData = {
        type: this.$route.params.type,
        isPreferential: this.isPreferential,
        hospital: localStorage.hospital,
        room: localStorage.room,
      };

      this.$ticketService.post('/ticket', postData)
        .then(() => {
          this.$router.push('/');
        })
        .catch(() => { })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>
